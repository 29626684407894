import { ethers, providers } from "ethers";

const provider = new providers.JsonRpcProvider(
  "https://goerli.blockpi.network/v1/rpc/public"
);
const { Contract } = ethers;

const initialContract = (address) =>
  new Contract(
    address,
    ["function balanceOf(address) external view returns (uint256)"],
    provider
  );

export const getBalance = (contractAddress, userAddress) => {
  const contract = initialContract(contractAddress);
  return contract.balanceOf(userAddress);
};
