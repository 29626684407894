var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"l-item logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require('@/assets/images/logo_desktop.svg'),"alt":"","title":""}})])],1),_c('div',{staticClass:"l-item item px-5 pb-6 pt-4",class:{
        active: _vm.$route.name === 'home',
        'border-b-4': _vm.$route.name === 'home',
      },on:{"mouseenter":_vm.addClass,"mouseleave":_vm.removeClass}},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" 楽天NFTトップ ")])],1),_c('div',{staticClass:"l-item item px-5 pb-6 pt-4",class:{
        active: _vm.$route.name === 'live',
        'border-b-4': _vm.$route.name === 'live',
      },on:{"mouseenter":_vm.addClass,"mouseleave":_vm.removeClass}},[_c('router-link',{attrs:{"to":"/live"}},[_vm._v(" Live stream ")])],1)]),_c('div',{staticClass:"header-right"},[_c('div',{staticClass:"r-item"},[_c('img',{attrs:{"src":require('@/assets/images/search.svg'),"alt":"","title":""}})]),_c('div',{staticClass:"r-item"},[_c('img',{attrs:{"src":require('@/assets/images/cart.svg'),"alt":"","title":""}})]),_c('vue-metamask',{ref:"metamask",attrs:{"initConnect":false},on:{"onComplete":_vm.onComplete}}),(_vm.isConnected)?_c('button',{staticClass:"btn-connect ml-6",on:{"click":_vm.disConnected}},[_vm._v(" "+_vm._s(_vm.walletTransform)+" ")]):_c('button',{staticClass:"btn-connect ml-6",on:{"click":function($event){return _vm.connect(true)}}},[_vm._v(" Connect wallet ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }