import { getBalance } from "./contract";

export default {
  methods: {
    async getUserAddres() {
      const accounts = await ethereum.request({method: 'eth_accounts'});
      return accounts[0]

    },
    async checkUserBalance() {
      // check wallet address can view
      const address = await this.getUserAddres();
      if (!address) return alert("Please connect wallet!");
      // check own bang balance
      // using getBalance => arg: contractAddress + userAddress
      const contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS;
      const balance = await getBalance(contractAddress, address);
      // const balance = 1;
      console.log('balance: ',balance.toNumber());
      const data = address && balance.toNumber();
      return data 
    },
    async userCanView() {
      const route = this.$route;
      if (route.name === "home") return;
      const balance = await this.checkUserBalance();
      if( !balance){
        this.$router.push({ name: "home" });
        return alert("You have no nft balance, Please check your balance!");
      }
    },
    getLiveVideo(){
      return process.env.LIVE_LINK || 'https://www.youtube.com/embed/p8_mU8xG_1Y'
    }
  },
};
