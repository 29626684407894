<template>
  <carousel-3d
    :height="450"
    :display="3"
    :perspective="0"
    :space="820"
    :autoplay="true"
    :inverse-scaling="1"
    :autoplay-timeout="3000"
    :animation-speed="1500"
    class="slider"
  >
    <slide :index="0" >
      <img :src="require('@/assets/images/sliders/slide1.webp')" alt=""  @click=""/>
    </slide>
    <slide :index="1">
      <img :src="require('@/assets/images/sliders/slide2.jpeg')" alt="" />
    </slide>
    <slide :index="2">
      <img :src="require('@/assets/images/sliders/slide1.webp')" alt="" />
    </slide>
    <slide :index="3">
      <img :src="require('@/assets/images/sliders/slide2.jpeg')" alt="" />
    </slide>
    <slide :index="4">
      <img :src="require('@/assets/images/sliders/slide1.webp')" alt="" />
    </slide>
    <slide :index="5">
      <img :src="require('@/assets/images/sliders/slide2.jpeg')" alt="" />
    </slide>
    <slide :index="6">
      <img :src="require('@/assets/images/sliders/slide1.webp')" alt="" />
    </slide>
    <slide :index="7">
      <img :src="require('@/assets/images/sliders/slide2.jpeg')" alt="" />
    </slide>
    <slide :index="8">
      <img :src="require('@/assets/images/sliders/slide1.webp')" alt="" />
    </slide>
  </carousel-3d>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "AppSlider",
  components: {
    Carousel3d,
    Slide,
  },
  methods:{
    goDetail(){
      this.$router.push({ name: "detail" });
    }
  }
};
</script>

<style lang="scss">
.slider {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .carousel-3d-slider {
    width: calc(40% - 10px) !important;
    .carousel-3d-slide {
      left: 20%;
      &.current {
        width: 203% !important;
        left: -50% !important;
      }
    }
  }
}
</style>
