<template>
  <div class="home">
    <slider />
    <div class="nfts">
      <div class="title text-center text-4xl">ショップ一覧</div>
      <items />
    </div>
    <div class="sell-items">
      <div class="title text-center text-4xl">瞬間</div>
      <sell-items />
    </div>
    <div class="nfts">
      <h3
        class="text-4xl font-bold m:text-32px s:text-2xl text-center mb-52px s:mb-10"
      >
        楽天NFTの特徴
      </h3>
      <nft />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Slider from "@/components/Slider";

export default {
  name: "HomeView",
  components: {
    Slider,
    Items: () => import("@/components/Items"),
    SellItems: () => import("@/components/SellItems"),
    Nft: () => import("@/components/nft"),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  img {
    margin-right: 10px;
  }
  .nfts,
  .sell-items {
    padding-top: 40px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
}
</style>
