<template>
  <div class="header-wrapper">
    <div class="header-left">
      <div class="l-item logo">
        <router-link to="/">
          <img
            :src="require('@/assets/images/logo_desktop.svg')"
            alt=""
            title=""
          />
        </router-link>
      </div>
      <div
        class="l-item item px-5 pb-6 pt-4"
        :class="{
          active: $route.name === 'home',
          'border-b-4': $route.name === 'home',
        }"
        @mouseenter="addClass"
        @mouseleave="removeClass"
      >
        <router-link to="/"> 楽天NFTトップ </router-link>
      </div>
      <div
        class="l-item item px-5 pb-6 pt-4"
        :class="{
          active: $route.name === 'live',
          'border-b-4': $route.name === 'live',
        }"
        @mouseenter="addClass"
        @mouseleave="removeClass"
      >
        <router-link to="/live"> Live stream </router-link>
      </div>
    </div>
    <div class="header-right">
      <div class="r-item">
        <img :src="require('@/assets/images/search.svg')" alt="" title="" />
      </div>
      <div class="r-item">
        <img :src="require('@/assets/images/cart.svg')" alt="" title="" />
      </div>
      
      <vue-metamask
        ref="metamask"
        @onComplete="onComplete"
        :initConnect="false"
      ></vue-metamask>
      <button class="btn-connect ml-6" v-if="isConnected" @click="disConnected">
        {{ walletTransform }}
      </button>
      <button class="btn-connect ml-6" @click="connect(true)" v-else>
        Connect wallet
      </button>
    </div>
  </div>
</template>

<script>
import VueMetamask from "vue-metamask";
const {ethereum} = window;
export default {
  name: "AppHeader",
  data() {
    return {
      address: "",
      isConnected: false
    };
  },
  components: {
    VueMetamask,
  },
  computed: {
    wallet() {
      return this.isConnected
        ? localStorage.getItem("wallet_address") || this.address
        : "";
    },
    walletTransform() {
      const addr = this.address;
      return addr.substr(0, 6) + "..." + addr.substr(-6);
    },
  },
  methods: {
    addClass(e) {
      e.target.classList.add("hover");
    },
    removeClass(e) {
      e.target.classList.remove("hover");
    },
    async onComplete(data) {
      const accounts = await ethereum.request({method: 'eth_accounts'});
      this.address = accounts[0]
      this.isConnected = true
    },
    async connect(check) {
      // console.log('here');
      const accounts = await ethereum.request({method: 'eth_accounts'});
      console.log(accounts);
      if(accounts.length > 0){
        this.isConnected = true
        this.address = accounts[0];
      }
      if (check) {
        this.$refs.metamask.init();
      }
      return accounts
    },
    disConnected(){
      this.isConnected = false
    }
  },
  mounted() {
    this.connect();
    window.ethereum.on('accountsChanged', (accounts) => {
      console.log('accounts');
      this.address = accounts[0]
    });
  },
  watch: {
    'address'(old, newValue){
      localStorage.setItem("wallet_address", newValue)
    }
  }
};
</script>

<style lang="scss">
.header-wrapper {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: white;
  box-shadow: 4px 2px 6px #e0e0e0;
  .header-left {
    display: flex;
    align-items: center;
    .l-item {
      &.logo {
        width: 200px;
        height: 40px;
        display: flex;
        align-items: center;
      }
      color: #000;
      font-weight: 700;
      &.item {
        border-color: rgba(125, 0, 190, 1);
        margin-right: 4px;
      }
    }
    .active {
      color: rgba(125, 0, 190, 1);
    }
    .hover {
      border-color: rgb(125, 0, 190);
      border-bottom-width: 4px;
      color: rgba(125, 0, 190, 1);
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
    padding-right: 2rem;
    .r-item {
      margin-right: 1rem;
      cursor: pointer;
    }
    .btn-connect {
      padding: 0.6rem 1rem;
      color: #fff;
      background-color: rgba(125, 0, 190, 1);
      border-radius: 10px;
      box-shadow: 4px 4px 4px #60476d70;
      cursor: pointer;
    }
  }
}
</style>
