import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/detail",
    name: "detail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ItemDetail.vue"),
  },
  {
    path: "/live",
    name: "live",
    component: () => import("../views/ViewLive.vue"),
  },
  {
    path: "/live-detail",
    name: "LiveDetail",
    component: () => import("../views/LiveDetail.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
